<template>
	<section>
		<div class="columns text-bold text-large">
			<div class="column col-md-12 col-4">
				<div class="card">
					<div class="card-header">
						<router-link :to="{name:'SV_Clientes'}" class="btn btn-primary float-right">
							<i class="icon icon-forward"></i>
						</router-link>
						<div class="card-subtitle text-dark">Clientes</div>
						<div class="card-title h4 text-primary">
							{{ total.clientes }}
						</div>
					</div>
				</div>
			</div>
			<div class="column col-md-12 col-4">
				<div class="card">
					<div class="card-header">
						<router-link :to="{name:'SV_Tecnicos'}" class="btn btn-primary float-right">
							<i class="icon icon-forward"></i>
						</router-link>
						<div class="card-subtitle text-dark">Técnicos</div>
						<div class="card-title h4 text-primary">
							{{ total.tecnicos }}
						</div>
					</div>
				</div>
			</div>
			<div class="column col-md-12 col-4">
				<div class="card">
					<div class="card-header">
						<div class="card-subtitle text-dark">Supervisores</div>
						<div class="card-title h4 text-primary">
							{{ total.usuarios }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="empty">
			<div class="empty-icon">
				<i class="icon icon-resize-horiz"></i>
			</div>
			<p class="empty-title h5">Proximamente nuevos elementos</p>
			<p class="empty-subtitle">Zona disponible para asignar información.</p>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Inicio',
		data() {
			return {
				total: {
					clientes: 'Cargando',
					usuarios: 'Cargando',
					tecnicos: 'Cargando'
				}
			};
		},
		mounted() {
			document.title = 'Inicio';
			this.infoUsuario();
			this.verDashboard();
		},
		methods: {
			infoUsuario() {
				if(Object.keys(this.$store.state.user).length === 0) {
					this.$store.state.api
						.get('usuario')
						.then(res => {
							this.$store.commit('setUser', res.data);
							if(res.data.tipo == 3) {
								this.$router.push({ name: 'TN_Ordenes' });
							}
						})
						.catch(err => alert(err.response.data.mensaje));
				}
			},
			verDashboard() {
				this.$store.state.api
					.get('dashboard')
					.then(res => this.total = res.data)
					.catch(err => alert(err.response.data.mensaje));
			}
		}
	};
</script>
